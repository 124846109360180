import React, {useContext, useState, useEffect} from 'react';
import styled from 'styled-components';
import Filter from '../components/filter';
import Ärendelista from '../components/TicketList';
import { TicketContext, TicketProvider } from '../context/TicketContext';

const ArendenContainer = styled.div`
  background: #F5F6FA;
  height: 100vh;
  padding: 20px;
  padding-top: 0px; /* 20px padding + 72.72px height of the topbar */
  overflow: hidden;
`;

const Title = styled.h1`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #202224;
  margin-bottom: 20px;
`;

const Arenden = () => {
  const { tickets, filterTickets } = useContext(TicketContext);
  
  
    

  //useEffect(() => {
    // Fetch tickets from an API or other data source
    // For example, you can use fetch or axios to get data
    // fetch('/api/tickets')
    //   .then(response => response.json())
    //   .then(data => setTickets(data));
  //}, []);

  const [filteredTickets, setFilteredTickets] = useState(null);

  // Function to filter tickets based on selected filters
  const handleFilter = ({ dates, type, status }) => {
    let filtered = [...tickets];

    if (dates?.startDate) {
      filtered = filtered.filter(ticket => {
        const ticketDate = new Date(ticket.receivedDate);
        return ticketDate >= dates.startDate;
      });
    }

    if (type) {
      filtered = filtered.filter(ticket => ticket.product.includes(type));
    }

    if (status) {
      filtered = filtered.filter(ticket => ticket.status === status);
    }

    setFilteredTickets(filtered);
  };

  useEffect(() => {
    setFilteredTickets(null); // Reset the filtered tickets when the original tickets array changes
  }, [tickets]);


  return (
    <TicketProvider>
      <ArendenContainer>
        <Title>Ärenden</Title>
        <Filter onFilter={handleFilter}/>
        
        <Ärendelista tickets={filteredTickets} />
          

      </ArendenContainer>
    </TicketProvider>  
  );
};

export default Arenden;
