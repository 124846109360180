import React from 'react';
import MonteringForm from './forms/MonteringForm';
import SkidserviceForm from './forms/SkidserviceForm';
import PjaxserviceForm from './forms/PjaxserviceForm';
import SpecialorderForm from './forms/SpecialorderForm';
import ReklamationerForm from './forms/ReklamationerForm';

const NewOrderTypeForm = ({ selectedType, onFormDataChange }) => {
  switch (selectedType) {
    case 'montering':
      return <MonteringForm onFormDataChange={onFormDataChange} />;
    case 'skidservice':
      return <SkidserviceForm onFormDataChange={onFormDataChange} />;
    case 'pjäxservice':
      return <PjaxserviceForm onFormDataChange={onFormDataChange} />;
    case 'specialorder':
      return <SpecialorderForm onFormDataChange={onFormDataChange} />;
    case 'reklamationer':
      return <ReklamationerForm onFormDataChange={onFormDataChange} />;
    default:
      return null;
  }
};

export default NewOrderTypeForm;
