import React, { useState } from 'react';
import styled from 'styled-components';
import Calendar from './CalendarDropdown'; // Import your Calendar component
import Arendestatus from './ArendestatusDropdown';
import Arendetyp from './ArendetypDropdown';
import filterIcon from '../images/filter.jpg';
import filterPil from '../images/pil.jpg';
import aterstall from '../images/aterstall.jpg';

const FilterBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 818px;
  height: 70px;
  background: #F9F9FB;
  border: 0.6px solid #D5D5D5;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }
`;

const FilterSection = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  cursor: pointer; /* Ensure the cursor indicates clickability */

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    padding: 5px 0;
  }
`;

const Icon = styled.img`
  width: ${({ size }) => size || '24px'};
  height: ${({ size }) => size || '24px'};
`;

const FilterText = styled.span`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: ${({ color }) => color || '#202224'};
`;

const ResetFilter = styled(FilterSection)`
  color: #EA0234;

  ${FilterText} {
    font-weight: 600;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 43px;
  left: 0;
  z-index: 10;
  background: #FFFFFF;
  border: 0.6px solid #D5D5D5;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
`;

const FilterBar = ({ onFilter }) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({ startDate: null, endDate: null });
  const [selectedType, setSelectedType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
    setIsTypeOpen(false);
    setIsStatusOpen(false);
  };

  const toggleTypeDropdown = () => {
    setIsTypeOpen(!isTypeOpen);
    setIsCalendarOpen(false);
    setIsStatusOpen(false);
  };

  const toggleStatusDropdown = () => {
    setIsStatusOpen(!isStatusOpen);
    setIsCalendarOpen(false);
    setIsTypeOpen(false);
  };

  const handleDateSelect = ({ day, month, year }) => {
  const selectedStartDate = new Date(year, month, day);
  setSelectedDateRange({ startDate: selectedStartDate, endDate: null });
};

  const applyFilters = () => {
    onFilter({ dates: selectedDateRange, type: selectedType, status: selectedStatus });
    setIsCalendarOpen(false);
    setIsTypeOpen(false);
    setIsStatusOpen(false);
  };

  const resetFilters = () => {
    setSelectedDateRange({ startDate: null, endDate: null });
    setSelectedType(null);
    setSelectedStatus(null);
    onFilter({ dates: null, type: null, status: null });
  };

  return (
    <FilterBarWrapper>
      <FilterSection>
        <Icon src={filterIcon} size="19.5px" alt="Filter icon" />
        <FilterText>Filtrera på</FilterText>
      </FilterSection>

      {/* Datum Dropdown */}
      <FilterSection onClick={toggleCalendar}>
        <FilterText>
          Datum 
          {selectedDateRange.startDate && `: ${selectedDateRange.startDate.toDateString()}`}
        </FilterText>
        <Icon src={filterPil} size="12px" alt="Dropdown arrow" />
        {isCalendarOpen && (
          <Calendar 
            onDateSelect={handleDateSelect} 
            selectedStartDate={selectedDateRange.startDate}
            selectedEndDate={selectedDateRange.endDate}
          />
        )}
      </FilterSection>

      {/* Ärendetyp Dropdown */}
      <FilterSection onClick={toggleTypeDropdown}>
        <FilterText>Ärendetyp {selectedType && `: ${selectedType}`}</FilterText>
        <Icon src={filterPil} size="12px" alt="Dropdown arrow" />
        {isTypeOpen && (
          <Arendetyp
            selectedType={selectedType}
            onSelectType={setSelectedType}
          />
        )}
      </FilterSection>

      {/* Ärendestatus Dropdown */}
      <FilterSection onClick={toggleStatusDropdown}>
        <FilterText>Ärendestatus {selectedStatus && `: ${selectedStatus}`}</FilterText>
        <Icon src={filterPil} size="12px" alt="Dropdown arrow" />
        {isStatusOpen && (
          <Arendestatus
            selectedStatus={selectedStatus}
            onSelectStatus={setSelectedStatus}
            onApply={applyFilters}
          />
        )}
      </FilterSection>

      {/* Reset Filter */}
      <ResetFilter onClick={resetFilters}>
        <Icon src={aterstall} size="18px" alt="Reset icon" />
        <FilterText color="#EA0234">Återställ Filter</FilterText>
      </ResetFilter>
    </FilterBarWrapper>
  );
};

export default FilterBar;

