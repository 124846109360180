import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TaskContainer = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.2fr 0.8fr;
  align-items: center;
  background: #FFFFFF;
  border-radius: 14px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
`;

const TaskItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F5F6FA;
  border: 0.6px solid #D5D5D5;
  border-radius: 19px;
  padding: 5px;
  text-align: center;
  width: 70%; /* Ensure the task item spans the full width of its container */
`;

const TaskText = styled.p`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #202224;
`;

const TaskButton = styled.button`
  background: #FF9500;
  color: white;
  border: none;
  border-radius: 14px;
  padding: 10px 15px;
  cursor: pointer;
  margin-left: 100px; /* Space between the last text item and the button */
  flex-shrink: 0; /* Prevent the button from shrinking */
`;

const Task = ({ taskId, taskName, dueDate, onButtonClick }) => {
  return (
    <TaskContainer>
      <TaskItem>
        <TaskText>{taskId}</TaskText>
      </TaskItem>
      <TaskItem>
        <TaskText>{taskName}</TaskText>
      </TaskItem>
      <TaskItem>
        <TaskText>{dueDate}</TaskText>
      </TaskItem>
      <div>
        <TaskButton onClick={onButtonClick}>Gå till ärende</TaskButton>
      </div>
    </TaskContainer>
  );
};

Task.propTypes = {
  taskId: PropTypes.string.isRequired,
  taskName: PropTypes.string.isRequired,
  dueDate: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

export default Task;



