// Layout.js
import React from 'react';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import { Outlet } from 'react-router-dom';

const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const MainContent = styled.div`
  flex-grow: 1;
  background: #F5F6FA;
  padding: 20px;
  padding-top: 92.72px; /* 20px padding + 72.72px height of the topbar */
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between widgets and tasks */
  overflow: hidden;

  @media (max-width: 768px) {
    padding-top: 120px; /* Adjust padding for mobile view */
  }
`;

const Layout = () => {
  return (
    <LayoutContainer>
      <Sidebar />
      <MainContent>
        <Topbar />
        <Outlet /> {/* Renders the matched child route */}
      </MainContent>
    </LayoutContainer>
  );
};

export default Layout;


