// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import DashboardContent from './components/DashboardContent';
import Arenden from './components/arenden';
import TicketPage from './components/TicketPage';
import NewTicket from './components/newticket';
import Kunder from './components/Kunder';
import KundSida from './components/KundSida';
import MessageHub from './components/messagehub';
import { TicketProvider } from './context/TicketContext';
import { MessageProvider } from './context/MessageProvider';
import './App.css';
import Login from './Login';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from './AuthContext';

const App = () => {
  return (
    <AuthProvider>
      <TicketProvider>
        <MessageProvider>
          <Router>
            <Routes>
              {/* Public Route: Login */}
              <Route path="/login" element={<Login />} />

              {/* Private Routes */}
              <Route element={<PrivateRoute />}>
                <Route element={<Layout />}>
                  <Route path="/" element={<DashboardContent />} />
                  <Route path="/dashboardcontent" element={<DashboardContent />} />
                  <Route path="/arenden" element={<Arenden />} />
                  <Route path="/ticket/:id" element={<TicketPage />} />
                  <Route path="/nyttarende" element={<NewTicket />} />
                  <Route path="/kunder" element={<Kunder />} />
                  <Route path="/customers/:customerId" element={<KundSida />} />
                  {/* MessageHub handles all routes under /meddelanden */}
                  <Route path="/meddelanden/*" element={<MessageHub />} />
                </Route>
              </Route>
            </Routes>
          </Router>
        </MessageProvider>
      </TicketProvider>
    </AuthProvider>
  );
};

export default App;







