import React, { useState } from 'react';
import styled from 'styled-components';

// The dropdown button
const DropdownButton = styled.button`
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 15px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #202224;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-top: -10px;
`;

// The styled dropdown menu
const DropdownMenu = styled.div`
  position: absolute;
  width: 521px;
  left: 50%; /* Center horizontally */
  transform: translateX(-91%); /* Adjust for perfect centering */
  top: 50px; /* Adjust as needed */
  background: #FFFFFF;
  box-shadow: 0px 13px 61px rgba(169, 169, 169, 0.37);
  border-radius: 26px;
  padding: 20px;
  z-index: 1000;

  @media (max-width: 600px) {
    width: 100%; /* Full width for small screens */
    left: 0;
    transform: none;
  }
`;

const PopupHeader = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #202224;
  margin-bottom: 20px;
`;

const StatusOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
`;

const StatusButton = styled.button`
  background: ${({ bgColor }) => bgColor};
  border: 0.6px solid #979797;
  border-radius: 17px;
  color: #FFFFFF;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 15px;
  cursor: pointer;
  min-width: 120px;

  &:hover {
    opacity: 0.8;
  }
`;

const SaveButton = styled.button`
  background: #FF9500;
  border-radius: 6px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #FFFFFF;
  padding: 10px 20px;
  cursor: pointer;
  display: block;
  margin: 0 auto;

  &:hover {
    opacity: 0.9;
  }
`;

const statusOptions = [
  { label: 'Färdigt', bgColor: '#007A22' },
  { label: 'Ska göras', bgColor: '#FF9500' },
  { label: 'Väntar på delar', bgColor: '#005CB8' },
  { label: 'Väntar på sulmått', bgColor: '#D30000' },
  { label: 'Väntar på produkt', bgColor: '#8800DB' }
];

const Andrastatus = ({ currentStatus, onStatusChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(currentStatus || 'Ändra status');

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (status) => {
    setSelectedStatus(status);
  };

  const handleSave = () => {
    onStatusChange(selectedStatus);
    setIsOpen(false);
  };
  

  return (
    <div style={{ position: 'relative' }}>
      <DropdownButton onClick={handleToggle}>
        {selectedStatus}
        <span>▼</span>
      </DropdownButton>
      {isOpen && (
        <DropdownMenu>
          <PopupHeader>Välj ärendestatus</PopupHeader>
          <StatusOptionsContainer>
            {statusOptions.map((option, index) => (
              <StatusButton
                key={index}
                bgColor={option.bgColor}
                onClick={() => handleSelect(option.label)}
                style={{
                  boxShadow:
                    selectedStatus === option.label
                      ? '0px 4px 4px rgba(0, 0, 0, 0.25)'
                      : 'none'
                }}
              >
                {option.label}
              </StatusButton>
            ))}
          </StatusOptionsContainer>
          <SaveButton onClick={handleSave}>Spara</SaveButton>
        </DropdownMenu>
      )}
    </div>
  );
};

export default Andrastatus;

