import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { TicketContext } from '../context/TicketContext'; // Import TicketContext

const KommentarContainer = styled.div`
  background: #FFFFFF;
  border: 0.6px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 90%;
`;

const Label = styled.label`
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  text-align: left;
  margin-bottom: 10px;
`;

const Textarea = styled.textarea`
  background: #FFFFFF;
  border: 0.6px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  padding: 10px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  outline: none;
  height: 150px;
  resize: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  background: #FF9500;
  border: none;
  border-radius: 19px;
  padding: 10px 20px;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  color: #FFFFFF;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: #FF7A00;
  }
`;

const NyKommentar = ({ name, email, phone, selectedType, formData }) => {
  const [comment, setComment] = useState('');
  const navigate = useNavigate();
  const { setTickets } = useContext(TicketContext); // Use setTickets from context

  const handleSubmit = async () => {
    try {
      let customerId = null;

      // Step 1: Ensure all required fields are present
      if (!name || !email || !phone) {
        console.error('Missing required fields: Name, Email, or Phone');
        alert('Please fill out all customer details before submitting.');
        return;
      }

      // Step 2: Check if customer exists
      const existingCustomerResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customers?name=${encodeURIComponent(name)}`);
      const existingCustomers = await existingCustomerResponse.json();

      if (existingCustomers.length > 0) {
        // Step 3: If customer exists, use the first match's ID
        customerId = existingCustomers[0].id;
      } else {
        // Step 4: If customer doesn't exist, create a new customer
        const createCustomerResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customers`, {  // Corrected line
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name, email, phone }),
        });
        if (!createCustomerResponse.ok) {
          console.error('Error creating customer:', createCustomerResponse.statusText);
          alert('Error creating customer. Please try again.');
          return;
        }
        const newCustomer = await createCustomerResponse.json();
        customerId = newCustomer.id;
      }

      // Step 5: Create a new ticket tied to the customer, including the comment
      const createTicketResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/tickets`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: selectedType,
          customer_id: customerId,
          kommentar: comment, // Include the comment here
          receiveddate: new Date().toISOString(), // Add the received date
          ...formData
          // Add other ticket fields here if necessary
        }),
      });

      // Handle errors in ticket creation
      if (!createTicketResponse.ok) {
        console.error('Error creating ticket:', createTicketResponse.statusText);
        alert('Error creating ticket. Please try again.');
        return;
      }

      const newTicket = await createTicketResponse.json();
      const ticketId = newTicket.ticket_id; // Capture the ticket_id from the response

      // Update the TicketContext with the new ticket
      setTickets(prevTickets => [...prevTickets, newTicket]);

      // Step 6: Redirect to the TicketPage
      navigate(`/ticket/${ticketId}`);
    } catch (error) {
      console.error('Error creating ticket:', error);
    }
  };

  return (
    <KommentarContainer>
      <Label>Kommentar</Label>
      <Textarea 
        placeholder="Skriv din kommentar här..." 
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <ButtonContainer>
        <SubmitButton onClick={handleSubmit}>Skapa Ärende</SubmitButton>
      </ButtonContainer>
    </KommentarContainer>
  );
};

export default NyKommentar;

