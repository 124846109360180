import React from 'react';
import styled from 'styled-components';

const WidgetWrapper = styled.div`
  background: #FFFFFF;
  border-radius: 14px;
  box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
  padding: 20px;
  width: 250px;
  text-align: center;
`;

const WidgetContent = styled.div`
  p {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #202224;
    opacity: 0.7;
  }

  h2 {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 700;
    font-size: 28px;
    color: #202224;
    letter-spacing: 1px;
  }
`;

const Widget = ({ icon, title, count }) => {
  return (
    <WidgetWrapper>
      {icon}
      <WidgetContent>
        <p>{title}</p>
        <h2>{count}</h2>
      </WidgetContent>
    </WidgetWrapper>
  );
};

export default Widget;
