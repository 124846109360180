// SearchDropdown.js
import React from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';

const DropdownContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  background: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 2000; /* Above the topbar */
`;

const DropdownItem = styled.div`
  padding: 10px 15px;
  cursor: pointer;
  transition: background 0.2s;
  z-index: 2000;

  &:hover, &:focus {
    background: #F0F0F0;
    outline: none;
  }
`;

const NoResults = styled.div`
  padding: 10px 15px;
  color: #888888;
`;

const SearchDropdown = ({ tickets, onItemClick }) => {
  if (tickets.length === 0) {
    return (
      <DropdownContainer>
        <NoResults>Inga resultat hittades.</NoResults> {/* "No results found." */}
      </DropdownContainer>
    );
  }

  return (
    <DropdownContainer>
      {tickets.slice(0, 10).map(ticket => (
        <DropdownItem 
          key={ticket.ticket_id}
          onClick={() => onItemClick(ticket)}
          tabIndex="0" // Make the item focusable
          role="button" // Inform assistive technologies of the clickable nature
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onItemClick(ticket);
            }
          }}
        >
          <strong>Ticket #{ticket.ticket_id}</strong> - {ticket.customer_name}
        </DropdownItem>
      ))}
    </DropdownContainer>
  );
};

export default SearchDropdown;
