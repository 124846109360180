import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const KundkortContainer = styled.div`
  background: #FFFFFF;
  border: 0.6px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 90%;
  box-sizing: border-box;
`;

const InputContainer = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
`;

const InputLabel = styled.label`
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  text-align: left;
  margin-bottom: 10px;
`;

const InputField = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  background: #FFFFFF;
  border: 0.6px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  padding: 0 10px;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  color: #000000;
`;

const Dropdown = styled.ul`
  margin-top: 5px;
  padding: 0;
  list-style-type: none;
  border: 1px solid #D5D5D5;
  border-radius: 19px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #F5F5F5;
  }
`;

const Nykund = ({ setName, setEmail, setPhone, name, email, phone }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [customerSelected, setCustomerSelected] = useState(false);
  const [isFetching, setIsFetching] = useState(false); // New state for loading indicator
  const [error, setError] = useState(null); // New state for errors

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (name && name.length > 2 && !customerSelected) {
        setIsFetching(true);
        fetch(`${process.env.REACT_APP_API_BASE_URL}/customers?name=${encodeURIComponent(name)}`, {
          cache: 'no-store',
        })
          .then((response) => response.json())
          .then((data) => setSearchResults(data))
          .catch((error) => {
            console.error('Error fetching customers:', error);
            setError('Failed to fetch customers');
          })
          .finally(() => setIsFetching(false));
      } else {
        setSearchResults([]);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [name, customerSelected]);

  const handleSelectCustomer = (customer) => {
    setName(customer.name);
    setEmail(customer.email);
    setPhone(customer.phone);
    setCustomerSelected(true);
    setSearchResults([]);
  };

  const handleNameChange = (e) => {
    setName(e.target.value || '');
    if (customerSelected) {
      setCustomerSelected(false);
      setEmail('');
      setPhone('');
    }
  };

  return (
    <KundkortContainer>
      <InputContainer>
        <InputLabel htmlFor="name">Namn</InputLabel>
        <InputField
          id="name"
          type="text"
          value={name}
          onChange={handleNameChange}
        />
        {isFetching && <div>Loading...</div>}
        {error && <div>{error}</div>}
        {searchResults.length > 0 && !customerSelected && (
          <Dropdown>
            {searchResults.map((customer) => (
              <DropdownItem
                key={customer.id}
                onClick={() => handleSelectCustomer(customer)}
              >
                {customer.name} (ID: {customer.id})
              </DropdownItem>
            ))}
          </Dropdown>
        )}
      </InputContainer>
      <InputContainer>
        <InputLabel htmlFor="email">E-mail</InputLabel>
        <InputField
          id="email"
          type="email"
          value={email}
          readOnly={customerSelected}
          onChange={(e) => setEmail(e.target.value)}
        />
      </InputContainer>
      <InputContainer>
        <InputLabel htmlFor="phone">Telefon</InputLabel>
        <InputField
          id="phone"
          type="tel"
          value={phone}
          readOnly={customerSelected}
          onChange={(e) => setPhone(e.target.value)}
        />
      </InputContainer>
    </KundkortContainer>
  );
};

export default Nykund

