import React, { useState } from 'react';
import styled from 'styled-components';

const OrderTypeContainer = styled.div`
  width: 97.5%;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 26px;
  padding: 20px;
`;

const OrderTypeTitle = styled.h2`
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #202224;
  margin-bottom: 20px;
`;

const Line = styled.div`
  width: 100%;
  margin-top: 10px;
  mix-blend-mode: normal;
  opacity: 0.5;
  border: 0.4px solid #979797;
`;

const OrderTypeButton = styled.div`
  flex: 1;
  margin: 0 10px; /* Space between buttons */
  padding: 10px 0;
  background: ${({ selected }) => (selected ? '#FF9500' : 'transparent')};
  border: 0.6px solid #979797;
  border-radius: 17px;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: ${({ selected }) => (selected ? '#FFFFFF' : '#202224')};
  cursor: pointer;
`;

const OrderTypeOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const ValjArendeBar = ({ setSelectedType }) => {
  const [selectedType, setLocalSelectedType] = useState('montering');

  const handleSelection = (type) => {
    setLocalSelectedType(type);
    setSelectedType(type);
  };

  return (
    <OrderTypeContainer>
      <OrderTypeTitle>Välj ärendetyp</OrderTypeTitle>
      <OrderTypeOptions>
        <OrderTypeButton
          selected={selectedType === 'montering'}
          onClick={() => handleSelection('montering')}
        >
          Montering
        </OrderTypeButton>
        <OrderTypeButton
          selected={selectedType === 'skidservice'}
          onClick={() => handleSelection('skidservice')}
        >
          Skidservice
        </OrderTypeButton>
        <OrderTypeButton
          selected={selectedType === 'pjäxservice'}
          onClick={() => handleSelection('pjäxservice')}
        >
          Pjäxservice
        </OrderTypeButton>
        <OrderTypeButton
          selected={selectedType === 'specialorder'}
          onClick={() => handleSelection('specialorder')}
        >
          Specialorder
        </OrderTypeButton>
        <OrderTypeButton
          selected={selectedType === 'reklamationer'}
          onClick={() => handleSelection('reklamationer')}
        >
          Reklamationer
        </OrderTypeButton>
      </OrderTypeOptions>
      <Line />
    </OrderTypeContainer>
  );
};

export default ValjArendeBar;

