import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import KundSok from './kundsok';
import KundLista from './kundlista';
import NyKundKnapp from './NyKundKnapp';

const KunderContainer = styled.div`
  background: #F5F6FA;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between components */
`;

const Title = styled.h1`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #202224;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchAndButtonContainer = styled.div`
  display: flex;
  gap: 20px; /* Space between the search bar and the button */
  align-items: center;
  flex: 1;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
`;

const LoadingIndicator = styled.div`
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
`;

const Kunder = () => {
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customers`);
      if (!response.ok) {
        throw new Error('Failed to fetch customers');
      }
      const data = await response.json();
      setCustomers(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const refreshCustomers = () => {
    fetchCustomers();
  };

  return (
    <KunderContainer>
      <Title>Kunder</Title>
      <ActionBar>
        <SearchAndButtonContainer>
          <KundSok onSearch={(e) => setSearchTerm(e.target.value)} />
          <NyKundKnapp refreshCustomers={refreshCustomers} />
        </SearchAndButtonContainer>
      </ActionBar>
      {error && <ErrorMessage>Error: {error}</ErrorMessage>}
      {loading ? (
        <LoadingIndicator>Hämtar kunder...</LoadingIndicator>
      ) : (
        <KundLista customers={customers} searchTerm={searchTerm} loading={loading} error={error} />
      )}
    </KunderContainer>
  );
};

export default Kunder;



