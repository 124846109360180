import React from 'react';
import styled from 'styled-components';
//import refreshIcon from './menuicons/update.jpg';  // Correct path to your image
//import archiveIcon from './menuicons/archive.jpg';  // Correct path to your image
//import deleteIcon from './menuicons/trash.jpg';  // Correct path to your image
import previousIcon from './menuicons/previous.jpg';  // Correct path to your image
import nextIcon from './menuicons/next.jpg';  // Correct path to your image

// Action bar container (below the top bar, aligned with content area)
const ActionBarContainer = styled.div`
  height: 50px;
  background: #FFFFFF;
  border: 1px solid #EDEFF1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  z-index: 1100;  // Ensure it's above the MailList but below TopBar
  width: 100%;  // Full width of the MailList container
  box-sizing: border-box;  // Ensure padding doesn't overflow
`;

// Group of action buttons (align left)
const ActionButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

// Button for archive, delete, and refresh
/*const ActionButton = styled.button`
  width: 22px;
  height: 22px;
  background: none;
  border: none;
  cursor: pointer;
  background-image: url(${props => props.icon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;*/

// Select-all checkbox
/*const SelectAll = styled.input.attrs({ type: 'checkbox' })`
  width: 22px;
  height: 22px;
  cursor: pointer;
`;*/

// Icon for pagination
const Pagination = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PaginationInfo = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
`;

// Arrow icons for pagination control
const ArrowIcon = styled.button`
  width: 22px;
  height: 22px;
  background: none;
  border: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.icon});
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

// Updated MhActionBar component
const MhActionBar = ({
  currentPage,
  setCurrentPage,
  totalMessages,
  messagesPerPage,
}) => {
  const totalPages = Math.ceil(totalMessages / messagesPerPage);
  const startMessage = totalMessages === 0 ? 0 : (currentPage - 1) * messagesPerPage + 1;
  const endMessage = Math.min(currentPage * messagesPerPage, totalMessages);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  return (
    <ActionBarContainer>
      {/* Action buttons on the left, commented out for now */}
      <ActionButtonsGroup>
        {/*<SelectAll />
        <ActionButton icon={refreshIcon} title="Refresh" />
        <ActionButton icon={archiveIcon} title="Archive" />
        <ActionButton icon={deleteIcon} title="Delete" />*/}
      </ActionButtonsGroup>

      {/* Pagination controls on the right */}
      <Pagination>
        <PaginationInfo>
          {startMessage}–{endMessage} of {totalMessages}
        </PaginationInfo>
        <ArrowIcon
          icon={previousIcon}
          title="Previous"
          onClick={handlePrevious}
          disabled={currentPage === 1}
        />
        <ArrowIcon
          icon={nextIcon}
          title="Next"
          onClick={handleNext}
          disabled={currentPage === totalPages || totalMessages === 0}
        />
      </Pagination>
    </ActionBarContainer>
  );
};

export default MhActionBar;