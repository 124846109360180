// MessageHub.js
import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MhLeftSidebar from './mh-leftsidebar';
import MhMailList from './mh-maillist';
import MhTopBar from './mh-topbar';
import MhActionBar from './mh-actionbar';
import MhTabs from './mh-tabs';
import ThreadView from './ThreadView';
import { MessageContext } from '../context/MessageProvider';
import styled from 'styled-components';

// Styled components
const MessageHubContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const MainContent = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`;

const MailListContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const MailListContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const MessageHub = () => {
  const [activeTab, setActiveTab] = useState('inbox');
  const [currentPage, setCurrentPage] = useState(1);
  const { fetchTotalMessagesForTab } = useContext(MessageContext);
  const [totalMessages, setTotalMessages] = useState(0);
  const messagesPerPage = 50; // Adjust as needed

  useEffect(() => {
    const fetchTotal = async () => {
      const total = await fetchTotalMessagesForTab(activeTab);
      setTotalMessages(total);
      setCurrentPage(1); // Reset to first page when activeTab changes
    };

    fetchTotal();
  }, [activeTab, fetchTotalMessagesForTab]);

  return (
    <MessageHubContainer>
      <MhTopBar />
      <MainContent>
        <MhLeftSidebar activeTab={activeTab} setActiveTab={setActiveTab} />
        <MailListContent>
          <MhActionBar
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalMessages={totalMessages}
            messagesPerPage={messagesPerPage}
          />
          <MhTabs activeTab={activeTab} setActiveTab={setActiveTab} />
          <MailListContainer>
            <Routes>
              <Route path="/" element={<Navigate to="inbox" />} />
              {/* Define routes for your tabs */}
              <Route path="inbox" element={<MhMailList activeTab="inbox" />} />
              <Route path="montering" element={<MhMailList activeTab="montering" />} />
              <Route path="skidservice" element={<MhMailList activeTab="skidservice" />} />
              <Route path="pjäxservice" element={<MhMailList activeTab="pjäxservice" />} />
              <Route path="reklamationer" element={<MhMailList activeTab="reklamationer" />} />
              <Route path="specialorder" element={<MhMailList activeTab="specialorder" />} />
              <Route path="klart-for-upphamtning" element={<MhMailList activeTab="klart-for-upphamtning" />} />
              <Route path="facebook" element={<MhMailList activeTab="facebook" />} />
              <Route path="instagram" element={<MhMailList activeTab="instagram" />} />
              {/* Route for ThreadView */}
              <Route path="thread/:threadId" element={<ThreadView />} />
              {/* Fallback route */}
              <Route path="*" element={<MhMailList activeTab={activeTab} currentPage={currentPage} messagesPerPage={messagesPerPage} />} />
            </Routes>
          </MailListContainer>
        </MailListContent>
      </MainContent>
    </MessageHubContainer>
  );
};

export default MessageHub;
