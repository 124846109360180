import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { TicketContext } from '../context/TicketContext';

const KommentarContainer = styled.div`
  background: #F5F6FA;
  border: 0.6px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 240px;
`;

const KommentarHeading = styled.h2`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-bottom: 10px;
`;

const KommentarTextarea = styled.textarea`
  width: 95%;
  height: 65%;
  background: #FFFFFF;
  border: 0.6px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  padding: 10px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #000000;
  resize: none;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

const KommentarText = styled.div`
  width: 95%;
  height: 65%;
  background: #FFFFFF;
  border: 0.6px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  padding: 10px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #000000;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

const EditButton = styled.button`
  background: #FF9500;
  border: 0.6px solid #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  cursor: pointer;
  margin-top: 10px;
  padding: 10px;
  align-self: flex-end;
`;

const Kommentar = ({ ticketId }) => {
  const { tickets, setTickets } = useContext(TicketContext);
  const ticket = tickets.find(ticket => ticket.id === ticketId);
  const [editing, setEditing] = useState(false);
  const [kommentar, setKommentar] = useState(ticket.kommentar || '');

  const handleEditToggle = () => {
    if (editing) {
      const updatedTickets = tickets.map(t =>
        t.id === ticketId ? { ...t, kommentar } : t
      );
      setTickets(updatedTickets);
    }
    setEditing(!editing);
  };

  return (
    <KommentarContainer>
      <KommentarHeading>Kommentar</KommentarHeading>
      <KommentarText isVisible={!editing}>{kommentar}</KommentarText>
      <KommentarTextarea
        isVisible={editing}
        value={kommentar}
        onChange={(e) => setKommentar(e.target.value)}
      />
      <EditButton onClick={handleEditToggle}>
        {editing ? 'Spara' : 'Redigera'}
      </EditButton>
    </KommentarContainer>
  );
};

export default Kommentar;



