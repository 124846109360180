import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import TicketList from './TicketList';
import RaderaKundKnapp from './RaderaKundKnapp';
import { TicketProvider } from '../context/TicketContext';  // Import TicketProvider

const KundSidaContainer = styled.div`
    padding: 20px;
    background-color: #f5f6fa;
    min-height: 100vh;
    width: 61%;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const CustomerName = styled.div`
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #202224;
`;

const TicketSection = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    display: flex;
  justify-content: flex-end; /* Aligns the button to the right */
  margin-bottom: 10px; /* Adds some space between the button and the ticket list */
`;

const KundSida = () => {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customers/${customerId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch customer details');
        }
        const data = await response.json();
        //console.log('Fetched customer data:', data); // Log the data for debugging
        setCustomer(data.customer);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomer();
  }, [customerId]);

  if (loading) {
    return <div>Loading customer details...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <KundSidaContainer>
      <HeaderContainer>
        <CustomerName>{customer.name || 'Okänd kund'}</CustomerName>
      </HeaderContainer>
      <ButtonContainer>
        <RaderaKundKnapp customerId={customerId} />
      </ButtonContainer>
      <TicketSection>
        <TicketList customerId={customerId} />
      </TicketSection>
    </KundSidaContainer>
  );
};

export default KundSida;
