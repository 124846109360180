import React from 'react';
import styled from 'styled-components';
import Widget from './Widget';
import TasksContainer from './TasksContainer';
import { useTickets } from '../context/TicketContext'; // Import useTickets

const DashboardContainer = styled.div`
  background: #F5F6FA;
  height: 100vh;
  padding: 20px;
  padding-top: 20px;
  overflow: hidden;
`;

const DashboardTitle = styled.h1`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #202224;
  margin-top: 0;
`;

const WidgetsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  gap: 20px;
`;

const Dashboard = () => {
  const { tickets } = useTickets(); // Access tickets from context

  // Function to count tickets of a specific type with status 'Ska göras'
  const countTickets = (type) => {
    return tickets.filter(
      (ticket) => ticket.type === type && ticket.status === 'Ska göras'
    ).length;
  };

  // Compute counts for each type
  const monteringarCount = countTickets('montering');
  const servicarCount = countTickets('skidservice');
  const pjaxorCount = countTickets('pjäxservice');

  const handleTaskButtonClick = (taskId) => {
    //console.log(`Task ${taskId} button clicked`);
    // Add logic for navigating to task or performing an action
  };

  return (
    <DashboardContainer>
      <DashboardTitle>Dashboard</DashboardTitle>
      <WidgetsContainer>
        <Widget
          icon={<div>{/* Include your icon here */}</div>}
          title="Monteringar att göra"
          count={monteringarCount}
        />
        <Widget
          icon={<div>{/* Include your icon here */}</div>}
          title="Servicar att göra"
          count={servicarCount}
        />
        <Widget
          icon={<div>{/* Include your icon here */}</div>}
          title="Pjäxor att göra"
          count={pjaxorCount}
        />
      </WidgetsContainer>

      <TasksContainer onTaskButtonClick={handleTaskButtonClick} />
    </DashboardContainer>
  );
};

export default Dashboard;







