import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { MessageContext } from '../context/MessageProvider';  // Import the context

// Sidebar container confined to the main content area
const MhSidebarContainer = styled.div`
  width: 248px;
  height: 86%;
  background: #FFFFFF;
  border: 1px solid #EDEFF1;
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;
  margin-top: 0px;

  @media (max-width: 768px) {
    width: 200px;
  }

  @media (max-width: 480px) {
    width: 60px;
    padding: 8px;
  }
`;

// Compose button style
const ComposeButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px 12px 12px;
  gap: 25px;
  width: 108px;
  height: 30px;
  margin-top: 20px;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 40px;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 48px;
    height: 48px;
    padding: 0;
  }
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  background: #FF9500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;

  @media (max-width: 480px) {
    width: 24px;
  }
`;

const ComposeLabel = styled.span`
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #3D4043;

  @media (max-width: 480px) {
    display: none;
  }
`;

// Nav section
const NavSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

// Styled NavLink for navigation items
const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px 4px 8px;
  width: 232px;
  height: 32px;
  background: ${props => (props.isActive ? '#FF9500' : '#FFFFFF')};
  border-radius: 0px 32px 32px 0px;
  cursor: pointer;
  text-decoration: none;
  color: #202124;

  &.active {
    background: #FF9500;
    font-weight: bold;
  }

  &:hover {
    background: rgba(255, 149, 0, 0.1);
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 4px 8px;
  }
`;

const NavItemText = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: ${props => (props.bold ? '700' : '400')};
  font-size: 14px;
  color: #202124;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const NotificationCount = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  background: #FF9500;
  padding: 2px 6px;
  border-radius: 12px;

  @media (max-width: 480px) {
    font-size: 10px;
  }
`;

const MhLeftSidebar = () => {
  const { unreadMessages } = useContext(MessageContext);  // Access unread messages from context
  const [labels, setLabels] = useState([]);

  // Fetch Gmail labels when the component mounts
  useEffect(() => {
    const fetchLabels = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/labels`);
        setLabels(res.data);  // Assuming the backend sends only filtered labels
      } catch (error) {
        console.error('Error fetching Gmail labels:', error);
      }
    };

    fetchLabels();
  }, []);

  // Map of internal labels to Gmail label IDs
  const labelMap = {
    montering: 'Label_3273969251363753377',
    skidservice: 'Label_933513062448309940',
    pjäxservice: 'Label_2954019126264607870',
    reklamationer: 'Label_1944855003908788370',
    specialorder: 'Label_4914797717326677264',
  };

  // Function to get the UI label name based on Gmail label ID
  const getLabelNameFromId = (labelId) => {
    return Object.keys(labelMap).find(key => labelMap[key] === labelId);
  };

  return (
    <MhSidebarContainer>
      {/* Compose button */}
      {/*<ComposeButton>
        <Icon />
        <ComposeLabel>Skicka</ComposeLabel>
      </ComposeButton>*/}

      {/* Static Navigation items */}
      <NavSection>
        <StyledNavLink to="/meddelanden/inbox">
          <NavItemText bold>Inbox</NavItemText>
          {unreadMessages.inbox > 0 && (
  <NotificationCount>{unreadMessages.inbox}</NotificationCount>
)}

        </StyledNavLink>

        {/* Dynamically render Gmail labels */}
        {labels.map((label) => {
          const uiLabelName = getLabelNameFromId(label.id);
          if (!uiLabelName) return null;

          return (
            <StyledNavLink key={label.id} to={`/meddelanden/${uiLabelName}`}>
              <NavItemText>{uiLabelName.charAt(0).toUpperCase() + uiLabelName.slice(1)}</NavItemText>
              {unreadMessages[uiLabelName] > 0 && (
                <NotificationCount>{unreadMessages[uiLabelName]}</NotificationCount>
              )}

            </StyledNavLink>
          );
        })}
      </NavSection>
    </MhSidebarContainer>
  );
};

export default MhLeftSidebar;
