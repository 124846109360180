import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const FormContainer = styled.div`
  background: #FFFFFF;
  border: 0.6px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const InputField = styled.div`
  flex-basis: 48%;
  background: #FFFFFF;
  border-radius: 19px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  text-align: left;
  margin-bottom: 5px;
`;

const Input = styled.input`
  background: #FFFFFF;
  border: 0.6px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  padding: 10px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  outline: none;
  flex-grow: 1;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 48%;
  margin-top: 30px;
`;

const CheckboxLabel = styled.label`
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  color: #1E1E1E;
`;

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  background: #2C2C2C;
  border-radius: 4px;
  margin-right: 10px;
`;

const SpecialorderForm = ({ onFormDataChange, initialData = {} }) => {
  const [product, setProduct] = useState(initialData.product || '');
  const [storlek, setStorlek] = useState(initialData.storlek || '');
  const [mottagenAv, setMottagenAv] = useState(initialData.mottagen_av || '');
  const [isChecked, setIsChecked] = useState(initialData.is_checked || false);

  useEffect(() => {
    onFormDataChange({
      product,
      storlek,
      mottagen_av: mottagenAv,
      is_checked: isChecked,
    });
  }, [product, storlek, mottagenAv, isChecked, onFormDataChange]);

  return (
    <FormContainer>
      <InputRow>
        <InputField>
          <Label>Produkt</Label>
          <Input type="text" value={product} onChange={(e) => setProduct(e.target.value)} />
        </InputField>
        <InputField>
          <Label>Storlek</Label>
          <Input type="text" value={storlek} onChange={(e) => setStorlek(e.target.value)} />
        </InputField>
      </InputRow>
      
      <InputRow>
        <InputField>
          <Label>Mottagen av</Label>
          <Input type="text" value={mottagenAv} onChange={(e) => setMottagenAv(e.target.value)} />
        </InputField>
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
          <CheckboxLabel>Beställd</CheckboxLabel>
        </CheckboxContainer>
      </InputRow>
    </FormContainer>
  );
};

export default SpecialorderForm;

