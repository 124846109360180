import React from 'react';
import styled from 'styled-components';

const KundkortContainer = styled.div`
  background: #F5F6FA;
  border: 0.6px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;  /* Make sure the component fills the height of its container */
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const FieldLabel = styled.label`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #000000;
  margin-bottom: 5px;
`;

const FieldValue = styled.div`
  background: #FFFFFF;
  border: 0.6px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  padding: 10px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #000000;
`;

const Kundkort = ({ name, email, phone }) => {
  return (
    <KundkortContainer>
      <FieldGroup>
        <FieldLabel>Namn</FieldLabel>
        <FieldValue>{name}</FieldValue>
      </FieldGroup>
      <FieldGroup>
        <FieldLabel>E-mail</FieldLabel>
        <FieldValue>{email}</FieldValue>
      </FieldGroup>
      <FieldGroup>
        <FieldLabel>Telefon</FieldLabel>
        <FieldValue>{phone}</FieldValue>
      </FieldGroup>
    </KundkortContainer>
  );
};

export default Kundkort;


