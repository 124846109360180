import React, { useState } from 'react';
import styled from 'styled-components';
import Task from './Task';
import { useTickets } from '../context/TicketContext';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  margin-top: 40px;
`;

const Title = styled.h2`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #202224;
  margin-bottom: 20px;
  opacity: 0.7;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 0.5fr 0.2fr 0.8fr;
  justify-content: space-between;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #202224;
  margin-bottom: 10px;
  padding: 10px 15px;
  background: #F5F6FA;
  border-radius: 14px;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 20px;
`;

const PageButton = styled.button`
  background: none;
  border: none;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin: 0 10px;
  cursor: pointer;
  color: ${props => (props.disabled ? '#ccc' : '#000')};

  &:disabled {
    cursor: not-allowed;
  }
`;

const TasksContainer = ({ onTaskButtonClick }) => {
  const { tickets } = useTickets(); // Use tickets from TicketContext
  const navigate = useNavigate(); // Initialize navigate

  const [currentPage, setCurrentPage] = useState(1);
  const tasksPerPage = 5;

  const getCurrentWeekTasks = () => {
    const startOfWeek = new Date();
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);

    return tickets.filter(ticket => {
      const taskDueDate = new Date(ticket.senast_klar);
      return taskDueDate >= startOfWeek && taskDueDate <= endOfWeek;
    });
  };

  const currentWeekTasks = getCurrentWeekTasks();

  const totalPages = Math.ceil(currentWeekTasks.length / tasksPerPage);
  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = currentWeekTasks.slice(indexOfFirstTask, indexOfLastTask);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(prev => prev + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(prev => prev - 1);
  };

  return (
    <Container>
      <Title>Ska vara klart den här veckan</Title>
      <Header>
        <p>Ärendenummer</p>
        <p>Produkt</p>
        <p>Klart till</p>
      </Header>
      {currentTasks.map(task => (
        <Task
          key={task.id}
          taskId={task.ticket_id}
          taskName={task.product}
          dueDate={new Date(task.senast_klar).toISOString().split('T')[0]}
          onButtonClick={() => navigate(`/ticket/${task.ticket_id}`)}
        />
      ))}
      {totalPages > 1 && (
        <Pagination>
          <PageButton onClick={handlePrevPage} disabled={currentPage === 1}>
            &lt; Föregående
          </PageButton>
          <span>
            Sida {currentPage} av {totalPages}
          </span>
          <PageButton onClick={handleNextPage} disabled={currentPage === totalPages}>
            Nästa &gt;
          </PageButton>
        </Pagination>
      )}
    </Container>
  );
};

export default TasksContainer;






