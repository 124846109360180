import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// Create the context
export const MessageContext = createContext();

// API function to fetch unread message counts from Gmail
const fetchUnreadMessagesFromGmail = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/unread-count`);  // Assuming your backend returns a structured response
    return response.data;  // Response should be an object with label counts
  } catch (error) {
    console.error('Error fetching unread Gmail messages:', error);
    return {
      inbox: 0,
      montering: 0,
      skidservice: 0,
      pjaxservice: 0,
      reklamationer: 0,
      specialorder: 0,
    };  // Return zeros in case of error
  }
};

// API function to fetch unread message counts from Facebook or Instagram
const fetchUnreadMessagesFromMeta = async (platform) => {
  // Simulate API call to Meta for unread messages (Facebook or Instagram)
  if (platform === 'Facebook Messenger') {
    return new Promise((resolve) => {
      setTimeout(() => resolve(1), 1000);  // Simulate 1 unread message in Messenger
    });
  } else if (platform === 'Instagram Direct Message') {
    return new Promise((resolve) => {
      setTimeout(() => resolve(6), 1000);  // Simulate 6 unread messages in Instagram
    });
  }
};

// Function to fetch threads for a specific Gmail label
// Function to fetch threads for a specific Gmail label
const fetchGmailThreadsForLabel = async (label, page = 1, messagesPerPage = 50) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/threads?label=${label}`,
      {
        params: {
          label,
          page,
          messagesPerPage,
        },
      });
    return response.data;  // This will now return detailed threads with metadata
  } catch (error) {
    console.error(`Error fetching Gmail threads for label ${label}:`, error);
    return [];
  }
};


// Updated function to fetch threads for the active tab (based on platform/label)
const fetchMessagesForTab = async (platform, page = 1, messagesPerPage = 50) => {
  const labelMap = {
    montering: 'Label_3273969251363753377',
    skidservice: 'Label_933513062448309940',
    pjäxservice: 'Label_2954019126264607870',
    reklamationer: 'Label_1944855003908788370',
    specialorder: 'Label_4914797717326677264',
  };

  try {
    if (platform === 'inbox') {
      // Fetch threads from all labels and combine them
      const labelsToFetch = Object.values(labelMap);
      let allThreads = [];

      const fetchedThreads = await Promise.all(
        labelsToFetch.map(label =>
          fetchGmailThreadsForLabel(label, page, messagesPerPage)
        )
      );

      allThreads = fetchedThreads.flat();
      allThreads.sort((a, b) => b.internalDate - a.internalDate);

      return allThreads.slice(0, messagesPerPage);
    } else if (labelMap[platform]) {
      return fetchGmailThreadsForLabel(labelMap[platform], page, messagesPerPage);
    } else {
      return [];
    }
  } catch (error) {
    console.error(`Error fetching messages for ${platform}:`, error);
    return [];
  }
};

const fetchTotalMessagesForTab = async (platform) => {
  const labelMap = {
    montering: 'Label_3273969251363753377',
    skidservice: 'Label_933513062448309940',
    pjäxservice: 'Label_2954019126264607870',
    reklamationer: 'Label_1944855003908788370',
    specialorder: 'Label_4914797717326677264',
  };

  try {
    if (platform === 'inbox') {
      // Sum total messages across all labels
      const labelsToFetch = Object.values(labelMap);
      let total = 0;

      for (const labelId of labelsToFetch) {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/totalMessages?label=${labelId}`);
        total += response.data.total;
      }

      return total;
    } else if (labelMap[platform]) {
      const labelId = labelMap[platform];
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/totalMessages?label=${labelId}`);
      return response.data.total;
    } else {
      return 0;
    }
  } catch (error) {
    console.error(`Error fetching total messages for ${platform}:`, error);
    return 0;
  }
};


// Provider component
export const MessageProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [unreadMessages, setUnreadMessages] = useState({
    'Ärendemail': 0,  // Placeholder for Gmail unread messages
    'Facebook Messenger': 0,
    'Instagram Direct Message': 0,
  });

  // Fetch unread messages from Gmail and Meta APIs
  useEffect(() => {
    const fetchUnreadCounts = async () => {
      try {
        const gmailUnreadCounts = await fetchUnreadMessagesFromGmail();
        const facebookUnread = await fetchUnreadMessagesFromMeta('Facebook Messenger');
        const instagramUnread = await fetchUnreadMessagesFromMeta('Instagram Direct Message');
  
        setUnreadMessages({
          ...gmailUnreadCounts,  // Spread Gmail unread counts from the API response
          'Facebook Messenger': facebookUnread,
          'Instagram Direct Message': instagramUnread,
        });
      } catch (error) {
        console.error('Error fetching unread messages:', error);
      }
    };
  
    fetchUnreadCounts();
  }, []);

  return (
    <MessageContext.Provider value={{ searchTerm, setSearchTerm, unreadMessages, fetchMessagesForTab, fetchTotalMessagesForTab, }}>
      {children}
    </MessageContext.Provider>
  );
};
