import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { MessageContext } from '../context/MessageProvider.js';

// Import logos directly
import arendemailLogo from './menuicons/gmail.jpg'; // Example path to the logo
import facebookLogo from './menuicons/facebook.jpg'; // Example path to the logo
import instagramLogo from './menuicons/instagram.jpg'; // Example path to the logo

const TabsBar = styled.div`
  width: 100%; /* Full width of the MailList container */
  background: #FFFFFF;
  border: 1px solid #EDEFF1;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  z-index: 1000; /* Ensure it's on top of MailList but below TopBar and ActionBar */
  height: 40px;
  margin: 0; /* Remove any margin that might affect stacking */
  gap: 30px;

  @media (max-width: 1024px) {
    max-width: 60%;
  }

  @media (max-width: 768px) {
    max-width: 80%;
    margin-left: 0;
  }

`;

// Single Tab container with logo and text, fixed width
const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
  width: 180px;  /* Equal width for each tab */
  padding: 10px;
  position: relative;
  flex-shrink: 0;

  &:hover {
    opacity: 0.8;
  }
`;

// Tab logo
const TabLogo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

// Tab label (including handling two lines for specific tabs)
const TabLabel = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: ${props => (props.active ? '#C84031' : 'rgba(0, 0, 0, 0.54)')};
  text-align: left;

  /* Two-line layout for Facebook Messenger and Instagram */
  ${props => props.isTwoLines && `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `}
`;

// Notification badge (hidden if no new messages)
const NotificationBadge = styled.div`
  display: ${props => (props.count > 0 ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  padding: 1px 6px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: ${props => props.bgColor};
  border-radius: 12px;
  margin-left: 8px;
`;

// Active Tab highlight
const TabHighlight = styled.div`
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #C84031;
  border-radius: 5px 5px 0px 0px;
  display: ${props => (props.active ? 'block' : 'none')};
`;

// Categories for each service with their respective logos
const categories = [
  { name: 'Ärendemail', path: '/meddelanden/inbox', logo: arendemailLogo, color: '#C84031', twoLines: false },
  { name: 'Facebook Messenger', api: 'facebook', logo: facebookLogo, color: '#3B5998', twoLines: true },
  { name: 'Instagram Direct Message', api: 'instagram', logo: instagramLogo, color: '#00A400', twoLines: true }
];

const MhTabs = ({ activeTab, setActiveTab }) => {
    const { unreadMessages } = useContext(MessageContext);
    const navigate = useNavigate();

  return (
    <TabsBar>
      {categories.map(category => (
        <Tab key={category.name}
        onClick={() => {
          setActiveTab(category.api ? category.api : category.path);
          if (!category.api) {
            navigate(category.path); // Navigate for Ärendemail routes
          }
        }}>
          {/* Tab logo */}
          <TabLogo src={category.logo} alt={category.name} />
          
          {/* Tab label */}
          <TabLabel active={activeTab === (category.api ? category.api : category.path)}>
            {category.twoLines ? (
              <>
                {category.name.split(' ')[0]} <br /> {category.name.split(' ')[1]}
              </>
            ) : (
              category.name
            )}
          </TabLabel>

          {/* Notification Badge */}
          <NotificationBadge count={unreadMessages[category.name]} bgColor={category.color}>
            {unreadMessages[category.name] > 0 ? `${unreadMessages[category.name]} nytt` : ''}
          </NotificationBadge>

          {/* Active Tab highlight */}
          <TabHighlight active={activeTab === (category.api ? category.api : category.path)} />
        </Tab>
      ))}
    </TabsBar>
  );
};

export default MhTabs;
