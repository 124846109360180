import React, { useState } from 'react';
import styled from 'styled-components';

const DropdownWrapper = styled.div`
  position: absolute;
  top: 43px;
  width: 521px;
  background: #FFFFFF;
  box-shadow: 0px 13px 61px rgba(169, 169, 169, 0.37);
  border-radius: 26px;
  padding: 20px;
  z-index: 10;
`;


const Header = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #202224;
  margin-bottom: 20px;
`;

const TypeOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const TypeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 147px;
  height: 34px;
  background: ${({ selected }) => (selected ? '#FF9500' : 'none')};
  color: ${({ selected }) => (selected ? '#FFFFFF' : '#202224')};
  border: 0.6px solid #979797;
  border-radius: 17px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background: ${({ selected }) => (selected ? '#FF9500' : '#F9F9FB')};
  }
`;

const Footer = styled.div`
  margin-top: 30px;
  text-align: center;
`;

const ApplyButton = styled.button`
  width: 129px;
  height: 36px;
  background: #FF9500;
  border-radius: 6px;
  border: none;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #FFFFFF;
  cursor: pointer;
`;

const TypeFilterDropdown = ({ selectedType, onSelectType, onApply }) => {
  const [localSelectedType, setLocalSelectedType] = useState(selectedType);

  const handleTypeClick = (type) => {
    setLocalSelectedType(type);
  };

  const handleApplyClick = () => {
    onSelectType(localSelectedType);
    onApply();
  };

  return (
    <DropdownWrapper>
      <Header>Välj ärendetyp</Header>
      <TypeOptions>
        <TypeButton
          selected={localSelectedType === 'Montering'}
          onClick={() => handleTypeClick('Montering')}
        >
          Montering
        </TypeButton>
        <TypeButton
          selected={localSelectedType === 'Skidservice'}
          onClick={() => handleTypeClick('Skidservice')}
        >
          Skidservice
        </TypeButton>
        <TypeButton
          selected={localSelectedType === 'Pjäxservice'}
          onClick={() => handleTypeClick('Pjäxservice')}
        >
          Pjäxservice
        </TypeButton>
        <TypeButton
          selected={localSelectedType === 'Specialorder'}
          onClick={() => handleTypeClick('Specialorder')}
        >
          Specialorder
        </TypeButton>
        <TypeButton
          selected={localSelectedType === 'Reklamationer'}
          onClick={() => handleTypeClick('Reklamationer')}
        >
          Reklamationer
        </TypeButton>
      </TypeOptions>
      <Footer>
        <ApplyButton onClick={handleApplyClick}>Välj ärendetyp</ApplyButton>
      </Footer>
    </DropdownWrapper>
  );
};

export default TypeFilterDropdown;