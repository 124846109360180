import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation to access state
import styled from 'styled-components';
import ValjArendeBar from './ValjArendeBar';
import Nykund from './NyKund';
import NewOrderTypeForm from './NewOrderTypeForm';
import NyKommentar from './NyKommentar';

const Container = styled.div`
  background: #F5F6FA;
  height: 100vh;
  padding: 20px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Heading = styled.h1`
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.114286px;
  color: #202224;
  margin-bottom: 0px;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns of equal width */
  grid-template-rows: auto 1fr; /* Automatically adjust rows, first auto, second takes available space */
  gap: 20px;
  align-items: start;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const NewTicket = () => {
  const location = useLocation(); // Retrieve state from location
  const { customer } = location.state || {}; // Access customer details from state

  const [selectedType, setSelectedType] = useState('montering');
  const [name, setName] = useState(customer?.name || '');
  const [email, setEmail] = useState(customer?.email || '');
  const [phone, setPhone] = useState(customer?.phone || '');
  const [formData, setFormData] = useState({});

  const handleFormDataChange = (newFormData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...newFormData,
    }));
  };

  return (
    <Container>
      <Heading>Nytt Ärende</Heading>
      <ValjArendeBar setSelectedType={setSelectedType} />
      <ContentContainer>
        <LeftColumn>
        <Nykund 
          name={name} 
          setName={setName} 
          email={email} 
          setEmail={setEmail} 
          phone={phone} 
          setPhone={setPhone} 
        />
          <NyKommentar 
             name={name} 
             email={email} 
             phone={phone} 
             selectedType={selectedType} 
             formData={formData} // Pass the form data to NyKommentar
             />
        </LeftColumn>
        <RightColumn>
          <NewOrderTypeForm 
             selectedType={selectedType}
             onFormDataChange={handleFormDataChange} // Handle form data changes 
          />
        </RightColumn>
      </ContentContainer>
    </Container>
  );
};

export default NewTicket;

