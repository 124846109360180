import React from 'react';
import styled from 'styled-components';

const ArendekortContainer = styled.div`
  background: #F5F6FA;
  border: 0.6px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const FieldGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
`;

const FieldWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 100px;
  margin-bottom: 7px;
`;

const FieldLabel = styled.label`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #000000;
  margin-bottom: 7px;
  
`;

const FieldValue = styled.div`
  background: #FFFFFF;
  border: 0.6px solid #D5D5D5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 19px;
  padding: 10px;
  width: 100%;
`;

const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const CheckboxLabel = styled.label`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #1E1E1E;
  margin-right: 10px;
`;

const Checkbox = styled.input`
  width: 16px;
  height: 16px;
  background: #2C2C2C;
  border-radius: 4px;
  margin-right: 80px;
`;

const TextAfterLabel = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #000000;
  
`;

export const ArendekortMontering = ({ ticket }) => (
  <ArendekortContainer>
    <FieldGroup>
      <FieldWrapper>
        <FieldLabel>Skida</FieldLabel>
        <FieldValue>{ticket.skida}</FieldValue>
      </FieldWrapper>
      <FieldWrapper>
        <FieldLabel>Bindning</FieldLabel>
        <FieldValue>{ticket.bindning}</FieldValue>
      </FieldWrapper>
    </FieldGroup>
    <FieldGroup>
      <FieldWrapper>
        <FieldLabel>Sulmått</FieldLabel>
        <FieldValue>{ticket.sulmatt}</FieldValue>
      </FieldWrapper>
      <FieldWrapper>
        <FieldLabel>Vikt</FieldLabel>
        <FieldValue>{ticket.vikt}</FieldValue>
      </FieldWrapper>
    </FieldGroup>
    <FieldGroup>
      <FieldWrapper>
        <FieldLabel>Monteringspunkt</FieldLabel>
        <FieldValue>{ticket.monteringspunkt}</FieldValue>
      </FieldWrapper>
      <FieldWrapper>
        <FieldLabel>Senast klar</FieldLabel>
        <FieldValue>{new Date(ticket.senast_klar).toLocaleDateString()}</FieldValue>
      </FieldWrapper>
    </FieldGroup>
    <CheckboxGroup>
      <CheckboxLabel>Mottaget av:</CheckboxLabel>
      <TextAfterLabel>{ticket.mottaget_av}</TextAfterLabel>
      <CheckboxLabel style={{ marginLeft: 'auto' }}>Betalt</CheckboxLabel>
      <Checkbox type="checkbox" checked={ticket.is_checked} readOnly />
    </CheckboxGroup>
  </ArendekortContainer>
);

export const ArendekortService = ({ ticket }) => (
  <ArendekortContainer>
    <FieldGroup>
    <FieldWrapper>
        <FieldLabel>Skida</FieldLabel>
        <FieldValue>{ticket.skida}</FieldValue>
      </FieldWrapper>
      <FieldWrapper>
        <FieldLabel>Servicetyp</FieldLabel>
        <FieldValue>{ticket.servicetyp}</FieldValue>
      </FieldWrapper>
    </FieldGroup>
    <FieldGroup>
      <FieldWrapper>
        <FieldLabel>Senast klar</FieldLabel>
        <FieldValue>{new Date(ticket.senast_klar).toLocaleDateString()}</FieldValue>
      </FieldWrapper>
      <FieldWrapper>
        
      </FieldWrapper>
    </FieldGroup>
    <CheckboxGroup>
      <CheckboxLabel>Mottaget av:</CheckboxLabel>
      <TextAfterLabel>{ticket['mottaget_av']}</TextAfterLabel>
      <CheckboxLabel style={{ marginLeft: 'auto' }}>Betalt</CheckboxLabel>
      <Checkbox type="checkbox" checked={ticket.is_checked} readOnly />
    </CheckboxGroup>
  </ArendekortContainer>
);

export const ArendekortPjaxor = ({ ticket }) => (
  <ArendekortContainer>
    <FieldGroup>
      <FieldWrapper>
        <FieldLabel>Pjäxa</FieldLabel>
        <FieldValue>{ticket.pjaxa}</FieldValue>
      </FieldWrapper>
      <FieldWrapper>
        <FieldLabel>Servicetyp</FieldLabel>
        <FieldValue>{ticket.servicetyp}</FieldValue>
      </FieldWrapper>
    </FieldGroup>
    <FieldGroup>
      <FieldWrapper>
        <FieldLabel>Senast klar</FieldLabel>
        <FieldValue>{new Date(ticket.senast_klar).toLocaleDateString()}</FieldValue>  
      </FieldWrapper>
      <FieldWrapper>
        
      </FieldWrapper>
    </FieldGroup>
    <CheckboxGroup>
      <CheckboxLabel>Mottaget av:</CheckboxLabel>
      <TextAfterLabel>{ticket['mottaget_av']}</TextAfterLabel>
      <CheckboxLabel style={{ marginLeft: 'auto' }}>Betalt</CheckboxLabel>
      <Checkbox type="checkbox" checked={ticket.is_checked} readOnly />
    </CheckboxGroup>
  </ArendekortContainer>
);

export const ArendekortSpecial = ({ ticket }) => (
  <ArendekortContainer>
    <FieldGroup>
      <FieldWrapper>
        <FieldLabel>Produkt</FieldLabel>
        <FieldValue>{ticket.product}</FieldValue>
      </FieldWrapper>
      <FieldWrapper>
        <FieldLabel>Storlek</FieldLabel>
        <FieldValue>{ticket.storlek}</FieldValue>
      </FieldWrapper>
    </FieldGroup>
    
    <CheckboxGroup>
      <CheckboxLabel>Mottaget av:</CheckboxLabel>
      <TextAfterLabel>{ticket['mottaget_av']}</TextAfterLabel>
      <CheckboxLabel style={{ marginLeft: 'auto' }}>Beställd</CheckboxLabel>
      <Checkbox type="checkbox" checked={ticket.is_checked} readOnly />
    </CheckboxGroup>
  </ArendekortContainer>
);

export const ArendekortReklamation = ({ ticket }) => (
  <ArendekortContainer>
    <FieldGroup>
      <FieldWrapper>
        <FieldLabel>Produkt</FieldLabel>
        <FieldValue>{ticket.product}</FieldValue>
      </FieldWrapper>
      <FieldWrapper>
        <FieldLabel>Orsak för reklamation</FieldLabel>
        <FieldValue>{ticket.orsak}</FieldValue>
      </FieldWrapper>
    </FieldGroup>
    
    <CheckboxGroup>
      <CheckboxLabel>Mottaget av:</CheckboxLabel>
      <TextAfterLabel>{ticket['mottaget_av']}</TextAfterLabel>
      
    </CheckboxGroup>
  </ArendekortContainer>
);

//export { ArendekortMontering, ArendekortService, ArendekortPjaxor };
