import React, { useState } from 'react';
import styled from 'styled-components';

const DropdownWrapper = styled.div`
  position: absolute;
  top: 43px;
  width: 521px;
  background: #FFFFFF;
  box-shadow: 0px 13px 61px rgba(169, 169, 169, 0.37);
  border-radius: 26px;
  padding: 20px;
  z-index: 1000;
`;

const Header = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #202224;
  margin-bottom: 20px;
`;

const StatusOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const StatusButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 147px;
  height: 34px;
  background: ${({ color }) => color || '#FFFFFF'};
  color: #FFFFFF;
  border: ${({ selected }) => (selected ? '2px solid #202224' : '0.6px solid #979797')};
  border-radius: 17px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  &:hover {
    opacity: 0.8;
  }
`;

const Footer = styled.div`
  margin-top: 30px;
  text-align: center;
`;

const ApplyButton = styled.button`
  width: 129px;
  height: 36px;
  background: #FF9500;
  border-radius: 6px;
  border: none;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #FFFFFF;
  cursor: pointer;
`;

const StatusFilterDropdown = ({ selectedStatus, onSelectStatus, onApply }) => {
  const [localSelectedStatus, setLocalSelectedStatus] = useState(selectedStatus);

  const handleStatusClick = (status) => {
    setLocalSelectedStatus(status);
  };

  const handleApplyClick = () => {
    onSelectStatus(localSelectedStatus);
    onApply();
  };

  return (
    <DropdownWrapper>
      <Header>Välj ärendestatus</Header>
      <StatusOptions>
        <StatusButton
          color="#007A22"
          selected={localSelectedStatus === 'Färdigt'}
          onClick={() => handleStatusClick('Färdigt')}
        >
          Färdigt
        </StatusButton>
        <StatusButton
          color="#FF9500"
          selected={localSelectedStatus === 'Ska göras'}
          onClick={() => handleStatusClick('Ska göras')}
        >
          Ska göras
        </StatusButton>
        <StatusButton
          color="#005CB8"
          selected={localSelectedStatus === 'Väntar på delar'}
          onClick={() => handleStatusClick('Väntar på delar')}
        >
          Väntar på delar
        </StatusButton>
        <StatusButton
          color="#D30000"
          selected={localSelectedStatus === 'Väntar på sulmått'}
          onClick={() => handleStatusClick('Väntar på sulmått')}
        >
          Väntar på sulmått
        </StatusButton>
        <StatusButton
          color="#8800DB"
          selected={localSelectedStatus === 'Väntar på produkt'}
          onClick={() => handleStatusClick('Väntar på produkt')}
        >
          Väntar på produkt
        </StatusButton>
      </StatusOptions>
      <Footer>
        <ApplyButton onClick={handleApplyClick}>Välj ärendestatus</ApplyButton>
      </Footer>
    </DropdownWrapper>
  );
};

export default StatusFilterDropdown;