import React from 'react';
import styled from 'styled-components';

const SearchContainer = styled.div`
  flex: 1;
  max-width: 300px;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 0.6px solid #D5D5D5;
  border-radius: 19px;
  padding-left: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const SearchInput = styled.input`
  font-family: 'Nunito Sans';
  font-size: 14px;
  color: #202224;
  border: none;
  outline: none;
  width: 100%;
  background: transparent;
  padding: 10px;
`;

const SearchIcon = styled.div`
  /* Add your icon styles here */
`;

const KundSok = ({ onSearch }) => {
  return (
    <SearchContainer>
      <SearchInput type="text" placeholder="Sök kund..." onChange={onSearch} />
      <SearchIcon />
    </SearchContainer>
  );
};

export default KundSok;

