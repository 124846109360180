import React from 'react';
import { ArendekortMontering, ArendekortService, ArendekortPjaxor, ArendekortSpecial, ArendekortReklamation } from '../context/ArendekortTypes';

const ticketTypeMapping = {
  montering: ArendekortMontering,
  skidservice: ArendekortService,
  pjäxservice: ArendekortPjaxor,
  specialorder: ArendekortSpecial,
  reklamationer: ArendekortReklamation,
};

const DynamicTicketComponent = ({ ticket }) => {
  const TicketComponent = ticketTypeMapping[ticket.type] || DefaultComponent;

  return <TicketComponent ticket={ticket} />;
};

const DefaultComponent = ({ ticket }) => (
  <div>No specific component for this ticket type.</div>
);

export default DynamicTicketComponent;
