import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { getIconByName } from './helpers/iconLoader'; // Assuming this helper function exists

const SidebarContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 242px;
  min-width: 242px;
  height: 100vh;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }
`;

const Logo = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 800;
  font-size: 24px;
  color: #000000;
  margin-bottom: 40px;
  text-align: center;

  span {
    color: #FF9500;
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const Icon = styled.div`
  width: 22px;
  height: 22px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 10px;
`;

const Label = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #202224;
`;

const activeStyle = css`
  background: #FF9500;
  color: #FFFFFF;

  ${Label} {
    color: #FFFFFF;
  }

  ${Icon} {
    visibility: hidden;
  }
`;

const MenuItem = styled(NavLink)`
  width: 80%;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 6px;
  transition: background 0.3s;
  position: relative;
  text-decoration: none;

  &.active {
    ${activeStyle}
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
    padding: 10px;
  }
`;

const Divider = styled.div`
  width: 80%;
  height: 1px;
  background-color: #E0E0E0;
  margin: 20px 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Sidebar = () => {
  // Define the menuItems array with icon names and labels
  const menuItems = [
    { label: 'Dashboard', iconName: 'dashboard.jpg', path: '/dashboardcontent' },
    { label: 'Nytt Ärende', iconName: 'skapa.jpg', path: '/nyttarende' },
    { label: 'Ärenden', iconName: 'ärenden.jpg', path: '/arenden' },
    { label: 'Kunder', iconName: 'kunder.jpg', path: '/kunder' },
    //{ label: 'Meddelanden', iconName: 'meddelanden.jpg', path: '/meddelanden' },
    //{ label: 'Inköpslista', iconName: 'inköpslista.jpg', path: '/inkopslista' },
    { label: 'Meddelanden', iconName: 'email.jpg', path: '/meddelanden' },
    //{ label: 'Skicka SMS', iconName: 'skicka-sms.jpg', path: '/skicka-sms' },
    //{ label: 'Nyhetsbrev', iconName: 'nyhetsbrev.jpg', path: '/nyhetsbrev' },
    //{ label: 'Ärendetyper', iconName: 'ärendetyper.jpg', path: '/arendetyper' },
    //{ label: 'Priser', iconName: 'ärenden.jpg', path: '/priser' },
    //{ label: 'Meddelandemallar', iconName: 'meddelandemallar.jpg', path: '/meddelandemallar' },
    //{ label: 'Inställningar', iconName: 'inställningar.jpg', path: '/installningar' },
    //{ label: 'Logga ut', iconName: 'logga-ut.jpg', path: '/loggaut' }
    // Add more menu items as needed
  ];

  return (
    <SidebarContainer>
      <Logo>
        Service<span>Drive</span>
      </Logo>
      {menuItems.map((item, index) => (
        <MenuItem
          key={index}
          to={item.path}
          activeclassName="active" // Applies the active class when the route matches
        >
          <Icon style={{ backgroundImage: `url(${getIconByName(item.iconName)})` }} />
          <Label>{item.label}</Label>
        </MenuItem>
      ))}
      <Divider />
    </SidebarContainer>
  );
};

export default Sidebar;



