// mh-topbar.js
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import LogoImage from './menuicons/messagehub.jpg';
import UserAvatar from './menuicons/skistore.png';
import { MessageContext } from '../context/MessageProvider'; // Import context

// Top bar container
const TopBarContainer = styled.div`
  height: 64px;
  background: #FFFFFF;
  border: 1px solid #EDEFF1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  z-index: 1000;
  box-shadow: inset 0px -1px 0px #EDEFF1;

  @media (max-width: 768px) {
    height: 64px;
  }

  @media (max-width: 480px) {
    height: 64px;
  }
`;

// Logo container
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 65px;
`;

const Logo = styled.img`
  height: 40px;
  width: auto;
`;

// Search bar styling
const SearchBar = styled.div`
  width: 100%;
  max-width: 720px;
  height: 40px;
  background: #F1F3F4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  margin-left: 0px;
  justify-content: center;

  @media (max-width: 1024px) {
    max-width: 60%;
  }

  @media (max-width: 768px) {
    max-width: 80%;
    margin-left: 0;
  }
`;

const SearchInput = styled.input`
  border: none;
  background: transparent;
  outline: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  width: 100%;
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  flex-grow: 1;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const SearchIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-image: url('path_to_search_icon'); // Replace with actual search icon path
  background-size: contain;
  background-repeat: no-repeat;
`;

const MhTopBar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const { setSearchTerm } = useContext(MessageContext); // Get the context function

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setSearchTerm(query); // Update the search term in context
  };

  return (
    <TopBarContainer>
      <LogoContainer>
        <Logo src={LogoImage} alt="MessageHub Logo" />
      </LogoContainer>

      <SearchBar>
        <SearchIcon />
        <SearchInput
          placeholder="Sök i mailen..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </SearchBar>

      <AvatarContainer>
        <Avatar src={UserAvatar} alt="User Avatar" />
      </AvatarContainer>
    </TopBarContainer>
  );
};

export default MhTopBar;
