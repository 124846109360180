import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import navigation hook
import styled from 'styled-components';

const ActionButton = styled.button`
  width: 127px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff8c00;
  border-radius: 4.5px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: white;

  &:hover {
    background-color: #e07b00;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const NyttArandeKnapp = ({ customer }) => {
    const navigate = useNavigate();
  
    const handleClick = () => {
      navigate('/nyttarende', { state: { customer } });
    };
  
    return <ActionButton onClick={handleClick}>Skapa nytt ärende</ActionButton>;
  };

export default NyttArandeKnapp;
