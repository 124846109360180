import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { MessageContext } from '../context/MessageProvider.js';
import { useNavigate } from 'react-router-dom';

// Main content area for routed pages
const StyledMailListContainer = styled.div`
  background: #FFFFFF;
  padding: 20px;
  border-radius: 0px;
  border: 1px solid #EDEFF1;
  flex-grow: 1;
  margin-left: 0;
  max-height: 88.4%;
  
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  

  @media (max-width: 768px) {
    height: auto;
    width: 100%;
  }

  @media (max-width: 1200px) {
    padding-right: 20px;
  }
`;

// Message row container
const MessageRow = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #EDEFF1;
  cursor: pointer;
  background-color: ${(props) => (props.isUnread ? '#E8F0FE' : '#FFFFFF')};

  &:hover {
    background-color: #F1F3F4;
  }

  @media (max-width: 910px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

// Left side of message row (sender, subject, snippet)
const MessageDetails = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;

  @media (max-width: 910px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

// Sender name
const Sender = styled.span`
  font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};
  color: #202124;
  margin-right: 10px;
  white-space: nowrap;

  @media (max-width: 910px) {
    margin-right: 0;
    white-space: normal;
  }
`;

// Subject and snippet container
const SubjectSnippet = styled.div`
  display: flex;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 910px) {
    flex-direction: column;
    white-space: normal;
    margin-top: 4px;
  }
`;

// Subject line
const Subject = styled.span`
  font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};
  color: #202124;
  margin-right: 5px;

  @media (max-width: 910px) {
    margin-right: 0;
  }
`;

// Message snippet
const Snippet = styled.span`
  color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 910px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

// Timestamp and actions (archive, delete, etc.)
const MessageActions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 910px) {
    margin-top: 8px;
    width: 85%;
    justify-content: space-between;
  }
`;

// Time
const Time = styled.span`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 10px;
  white-space: nowrap;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
`;

// Example icons for actions
const ArchiveIcon = () => <span>🗄️</span>;
const DeleteIcon = () => <span>🗑️</span>;
const ReadIcon = () => <span>📧</span>;

const MhMailList = ({ activeTab, currentPage, messagesPerPage }) => {
  const { fetchMessagesForTab, searchTerm } = useContext(MessageContext);
  const [threads, setThreads] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchThreads = async () => {
        try {
            const fetchedThreads = await fetchMessagesForTab(
                activeTab,
                currentPage,
                messagesPerPage
            );

            if (fetchedThreads && Array.isArray(fetchedThreads)) {
                if (searchTerm) {
                    // Filter messages on the client side
                    const filteredThreads = fetchedThreads.filter(thread => {
                        return thread.messages.some(message => {
                            const { subject, snippet } = message;
                            return (
                                (subject && subject.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                (snippet && snippet.toLowerCase().includes(searchTerm.toLowerCase()))
                            );
                        });
                    });
                    setThreads(filteredThreads);
                } else {
                    // No search term, show all threads
                    setThreads(fetchedThreads);
                }
            } else {
                setThreads([]);
            }
        } catch (error) {
            console.error('Error fetching threads:', error);
            setThreads([]);
        }
    };

    fetchThreads();
}, [activeTab, currentPage, messagesPerPage, fetchMessagesForTab, searchTerm]);


  const extractHeader = (headers, name) => {
    if (!headers || !Array.isArray(headers)) return 'Unknown';
    const header = headers.find((h) => h.name === name);
    return header ? header.value : 'Unknown';
  };

  const extractSenderName = (fromHeader) => {
    if (!fromHeader) return 'Unknown';
    const regex = /^(.*?)(?=\s*<)/; // Match everything before the email address
    const match = fromHeader.match(regex);
    if (match && match[1]) {
      return match[1].replace(/"/g, '').trim();
    } else {
      return fromHeader;
    }
  };

  return (
    <StyledMailListContainer>
      {threads && threads.length > 0 ? (
        threads.map((thread) => {
          if (!thread.messages || thread.messages.length === 0) return null;

          // **Find the latest message in the thread**
          const messages = thread.messages;

          // Assuming messages are ordered by date, we can take the last one
          // If not, we can sort them by internalDate
          const latestMessage = messages.reduce((latest, current) => {
            return parseInt(current.internalDate) > parseInt(latest.internalDate)
              ? current
              : latest;
          }, messages[0]);

          // **Determine if the thread is unread**
          const isUnread = messages.some(
            (message) =>
              Array.isArray(message.labelIds) && message.labelIds.includes('UNREAD')
          );

          const headers = latestMessage.payload ? latestMessage.payload.headers || [] : [];
          const internalDate = latestMessage.internalDate
            ? new Date(parseInt(latestMessage.internalDate)).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              })
            : 'Unknown time';

          const fromHeader = extractHeader(headers, 'From');
          const senderName = extractSenderName(fromHeader);
          const subject = extractHeader(headers, 'Subject') || '(No Subject)';
          const snippet = latestMessage.snippet || '';

          return (
            <MessageRow
              key={thread.id}
              onClick={() => navigate(`/meddelanden/thread/${thread.id}`)}
              isUnread={isUnread}
            >
              <MessageDetails>
                <Sender isBold={isUnread}>{senderName}</Sender>
                <SubjectSnippet>
                  <Subject isBold={isUnread}>{subject}</Subject>
                  <Snippet>
                    {' - '}
                    {snippet}
                  </Snippet>
                </SubjectSnippet>
              </MessageDetails>

              <MessageActions>
                <Time>{internalDate}</Time>
                {/*<IconButton title="Archive">
                  <ArchiveIcon />
                </IconButton>
                <IconButton title="Delete">
                  <DeleteIcon />
                </IconButton>
                <IconButton title="Mark as Read/Unread">
                  <ReadIcon />
                </IconButton>*/}
              </MessageActions>
            </MessageRow>
          );
        })
      ) : (
        <p>Inga mail i inkorgen</p>
      )}
    </StyledMailListContainer>
  );
};

export default MhMailList;
