import React, { useState } from 'react';
import styled from 'styled-components';

const CalendarWrapper = styled.div`
  position: absolute;
  top: 43px;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #FFFFFF; /* Solid white background */
  border-radius: 16px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Keep the shadow as is */
  width: 250px;
  border: 1px solid #E0E0E0; /* Optional: Add a border for extra definition */
  z-index: 1000; /* Ensure it is above other elements */
  pointer-events: auto; /* Ensure it can capture pointer events */
`;

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

const MonthYear = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #202224;
`;

const NavigationButton = styled.button`
  background: #FFFFFF;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
`;

const DaysOfWeek = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  color: #B5B5B5;
  margin-bottom: 10px;
`;

const DaysGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  width: 100%;
`;

const DayButton = styled.button`
  background: ${({ isSelected }) => (isSelected ? '#FF9500' : 'transparent')};
  color: ${({ isSelected }) => (isSelected ? '#FFFFFF' : '#202224')};
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;

  &:hover {
    background: ${({ isSelected }) => (isSelected ? '#FF9500' : '#F0F0F0')};
  }
`;

const InfoText = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  color: #A5A5A5;
  margin-top: 10px;
`;

const ConfirmButton = styled.button`
  background: #FF9500;
  border: none;
  color: #FFFFFF;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 20px;
  cursor: pointer;

  &:hover {
    background: #FF7A00;
  }
`;

const CalendarDropdown = ({ onDateSelect }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateClick = (date) => {
    const formattedDate = new Date(2019, 1, date); // February 2019
    setSelectedDate(formattedDate);
  };

  const applyDate = () => {
    if (selectedDate) {
      onDateSelect({
        day: selectedDate.getDate(),
        month: selectedDate.getMonth(),
        year: selectedDate.getFullYear()
      });
    }
  };

  return (
    <CalendarWrapper onClick={(e) => e.stopPropagation()}>
      <CalendarHeader>
        <NavigationButton>{'<'}</NavigationButton>
        <MonthYear>February 2019</MonthYear>
        <NavigationButton>{'>'}</NavigationButton>
      </CalendarHeader>
      <DaysOfWeek>
        <div>S</div>
        <div>M</div>
        <div>T</div>
        <div>O</div>
        <div>T</div>
        <div>F</div>
        <div>L</div>
      </DaysOfWeek>
      <DaysGrid>
        {[...Array(28)].map((_, index) => {
          const day = index + 1;
          const isSelected = selectedDate instanceof Date && selectedDate.getDate() === day;

          return (
            <DayButton
              key={index}
              isSelected={isSelected}
              onClick={() => handleDateClick(day)}
            >
              {day}
            </DayButton>
          );
        })}
      </DaysGrid>
      <InfoText>*Det går att välja flera datum</InfoText>
      <ConfirmButton onClick={applyDate}>Välj datum</ConfirmButton>
    </CalendarWrapper>
  );
};

export default CalendarDropdown;






